import { createStore } from "vuex";
import JobService from '@/services/JobService.js'

export default createStore({
  state: {
    user: 'Jawad',
    jobs: [],
    job: {}
  },
  mutations: {
    ADD_JOB(state, job) {
      state.jobs.push(job)
    },
    SET_JOB(state, job) {
      state.job = job
    },
    SET_JOBS(state, jobs) {
      state.jobs = jobs
    }
  },
  actions: {
    createJob({ commit }, job) {
      return JobService.postJob(job)
		  .then(() => {
		    commit('ADD_JOB', job)
	    })
	    .catch(error => {
		    throw(error)
	    })
    },
    fetchJobs({ commit }) {
      return JobService.getJobs()
        .then(response => {
          commit('SET_JOBS', response.data)
        })
        .catch(error => {
          throw(error)
        })
    },
    fetchJob({ commit, state }, id) {  
      const existingJob = state.jobs.find(job => job.id === id)
        if (existingJob) {
          commit('SET_JOB', existingJob)
        } else {
        return JobService.getJob(id)
        .then(response => {
          commit('SET_JOB', response.data)
        })
        .catch(error => {
          throw(error)
        })
      }
    }
  },
  modules: {}
})