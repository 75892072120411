import { createRouter, createWebHistory } from "vue-router";
import MachineList from "../views/MachineList.vue";
import MachineDetails from "../views/MachineDetails.vue";
import MachineQueue from "../views/MachineQueue.vue";
import JobList from "../views/JobList.vue";
import JobDetails from "../views/JobDetails.vue";
import JobCreate from "../views/JobCreate.vue";
import About from "../views/About.vue";
import NotFound from '@/views/NotFound.vue';
import NetworkError from '@/views/NetworkError.vue';
import NProgress from "nprogress";

const routes = [
  {
    path: "/",
    name: "MachineList",
    component: MachineList,
  },
  {
    path: "/machine/:id",
    name: "MachineDetails",
    props: true,
    component: MachineDetails,
  },
  {
    path: "/queue",
    name: "MachineQueue",
    component: MachineQueue,
  },
  {
    path: "/job",
    name: "JobList",
    component: JobList
  },
  {
    path: "/job/:id",
    name: "JobDetails",
    props: true,
    component: JobDetails,
  },
  {
    path: '/job/create',
    name: 'JobCreate',
    component: JobCreate
  },
  {
    path: "/about",
    name: "About",
    component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/404/:resource',
    name: '404Resource',
    component: NotFound,
    props: true
  },
  {
    path: '/network-error',
    name: 'NetworkError',
    component: NetworkError
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition){
    if (savedPosition) {
      return savedPosition
    }
    else {
      return {top: 0}
    }
  }
});

router.beforeEach(() => {
  NProgress.start()
})

router.afterEach(() => {
  NProgress.done()
})

export default router;
