<template>
  <router-link class="job-link" :to="{ name: 'JobDetails', params: {id: job.id } }">
    <div class="machine-row-card" :class="{ inProgress: job.processing }">
      <span>{{ job.processesRemaining }} Processes remaining for {{ job.name }}</span>
      <h4>{{ job.timeRemaining }} days remaining for completion</h4>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "MachineRowCard",
  props: {
    job: Object,
  },
};
</script>

<style scoped>
.machine-row-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-left: 0.5em;
  margin-right: 0.5em;

  background: white;
}
.machine-row-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.inProgress {
  background: greenyellow;
}

.job-link {
  color: #2c3e50;
  text-decoration: none;
}

@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

</style>
