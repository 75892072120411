<template>
  <router-link class="machine-link" :to="{ name: 'MachineDetails', params: {id: machine.id } }">
    <div class="machine-row">
      <div class="rotate">{{ machine.title }}</div>
      <MachineRowCard v-for="job in machine.jobs" :key="job.id" :job="job" />
    </div>
  </router-link>
</template>

<script>
import MachineRowCard from "@/components/MachineRowCard.vue";

export default {
  name: "MachineRow",
  props: {
    machine: Object,
  },
  components: {
    MachineRowCard,
  },
};
</script>

<style scoped>
.machine-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 20px;
  /*width: 80vw;*/
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;

  background: lightgray;
}
.machine-row:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.machine-link {
  color: #2c3e50;
  text-decoration: none;
}

.rotate {
writing-mode: vertical-lr;

display:flex;
vertical-align: middle;
justify-content:center;
align-items:center;
}

</style>
