import axios from 'axios'

const apiClient = axios.create({
    baseURL: 'http://localhost:8080/api/',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    getJobs() {
        return apiClient.get('/jobs')
    },
    getJob(id) {
        return apiClient.get('/jobs'+ id)
    },
    postJob(job) {
        return apiClient.post('/jobs', job)
    }
}