<template>
  <div v-if="machine"> <!-- wait for compenent to load from aync Promise to avoid null error in console-->
    <h1>{{ machine.title }}</h1>
    <p>{{ machine.time }} on {{ machine.date }} @ {{ machine.location }}</p>
    <p>{{ machine.description }}</p>
  </div>
</template>

<script>
import MachineService from '@/services/MachineService.js'
export default {
  props: ['id'],
  data() {
    return {
      machine: null
    }
  },
  created() {
    MachineService.getMachine(this.id)
      .then(response => {
        this.machine = response.data
      })
      .catch(error => {
        if (error.response && error.response.status == 404) {
          this.$router.push({
            name: '404Resource',
            params: { resource: 'machine' }
          })
        } else {
          this.$router.push({ name: 'NetworkError' })
        }
      })
  }
}
</script>

<style>

</style>