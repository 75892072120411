<template>
  <h1>Job Status</h1>
  <div class="jobs">
    <JobCard v-for="job in jobs" :key="job.id" :job="job" />
  </div>
</template>

<script>
// @ is an alias to /src
import JobCard from "@/components/JobCard.vue";

export default {
  name: "JobList",
  components: {
    JobCard,
  },
  /*data() {
    return {
      jobs: [
        {
          id: 5928101,
          customer: "Van Heusen",
          owner: "Adam",
          name: "Camfil Job 1589",
          status: "To be dispatched",
          expectedDeliveryDate: "January 28, 2024",
          daysRemaining: 2,
          processesRemaining: 0,
        },
        {
          id: 4582797,
          customer: "Van Heusen",
          owner: "Adam",
          name: "Camfil Job 8974",
          status: "In Progress",
          expectedDeliveryDate: "January 28, 2024",
          daysRemaining: 3,
          processesRemaining: 6,
        },
        {
          id: 8419988,          
          customer: "Pan America",
          owner: "Anna",
          name: "PA Job 4287",
          status: "Awaiting Allocation",
          expectedDeliveryDate: "January 28, 2024",
          daysRemaining: 10,
          processesRemaining: 9,
        },
      ],
    };
  },*/
  created() {
    this.$store.dispatch('fetchJobs')
      .catch(error => {
        this.$router.push({
          name: 'ErrorDisplay',
          params: { error: error }
        })
      })
  },
  computed: {
    jobs() {
      return this.$store.state.jobs
    }
  }
};
</script>

<style scoped>
.jobs {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>