<template>
  <h1>Machines Jobs in Progress</h1>
  <div class="machines">
    <MachineCard v-for="machine in machines" :key="machine.id" :machine="machine" />
  </div>
</template>

<script>
// @ is an alias to /src
import MachineCard from "@/components/MachineCard.vue";
import MachineService from '@/services/MachineService.js'

export default {
  name: "MachineList",
  components: {
    MachineCard,
  },
  data() {
    return {
      machines: [
        {
          id: 5928101,
          title: "CNC Cutting Machine",
          date: "January 28, 2024",
          time: "12:00",
          job: "Camfil Job 1589",
        },
        {
          id: 4582797,
          category: "food",
          title: "CNC bending",
          description: "Join us as we tend to the community edible plants.",
          location: "Flora City",
          date: "March 14, 2024",
          time: "10:00",
          petsAllowed: true,
          organizer: "Fern Pollin",
          job: "Camfil Job 8974",
        },
        {
          id: 8419988,
          category: "sustainability",
          title: "Engraving laser",
          description: "Help pick up trash along the shore.",
          location: "Playa Del Carmen",
          date: "July 22, 2024",
          time: "11:00",
          petsAllowed: false,
          organizer: "Carey Wales",
          job: "PA Job 4287",
        },
        {
          id: 8419989,
          category: "sustainability",
          title: "Welding grinding",
          description: "Help pick up trash along the shore.",
          location: "Playa Del Carmen",
          date: "July 22, 2024",
          time: "11:00",
          petsAllowed: false,
          organizer: "Carey Wales",
          job: "PA Job 4287",
        },
        {
          id: 8419990,
          category: "sustainability",
          title: "Mechanical processing",
          description: "Help pick up trash along the shore.",
          location: "Playa Del Carmen",
          date: "July 22, 2024",
          time: "11:00",
          petsAllowed: false,
          organizer: "Carey Wales",
          job: "PA Job 4287",
        },
        {
          id: 8419991,
          category: "sustainability",
          title: "Arm assembly",
          description: "Help pick up trash along the shore.",
          location: "Playa Del Carmen",
          date: "July 22, 2024",
          time: "11:00",
          petsAllowed: false,
          organizer: "Carey Wales",
          job: "PA Job 4287",
        },
        {
          id: 8419992,
          category: "sustainability",
          title: "Installation of devices",
          description: "Help pick up trash along the shore.",
          location: "Playa Del Carmen",
          date: "July 22, 2024",
          time: "11:00",
          petsAllowed: false,
          organizer: "Carey Wales",
          job: "PA Job 4287",
        },
        {
          id: 8419993,
          category: "sustainability",
          title: "Electrical installation",
          description: "Help pick up trash along the shore.",
          location: "Playa Del Carmen",
          date: "July 22, 2024",
          time: "11:00",
          petsAllowed: false,
          organizer: "Carey Wales",
          job: "PA Job 4287",
        },
        {
          id: 8419994,
          category: "sustainability",
          title: "Picking",
          description: "Help pick up trash along the shore.",
          location: "Playa Del Carmen",
          date: "July 22, 2024",
          time: "11:00",
          petsAllowed: false,
          organizer: "Carey Wales",
          job: "PA Job 4287",
        },
      ],
    };
  },
  created() {
    MachineService.getMachines()
      .then(response => {
        console.log('machines:', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
};
</script>

<style scoped>
.machines {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>