<template>
  <router-link class="machine-link" :to="{ name: 'MachineDetails', params: {id: machine.id } }">
    <div class="machine-card">
      <h2>{{ machine.title }}</h2>
      <span>Job started @ {{ machine.time }} on {{ machine.date }}</span>
      <h4>{{ machine.job }} - In Progress
      <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
      </h4>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "MachineCard",
  props: {
    machine: Object,
  },
};
</script>

<style scoped>
.machine-card {
  padding: 20px;
  width: 80vw;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}
.machine-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.machine-link {
  color: #2c3e50;
  text-decoration: none;
}

@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }
</style>
