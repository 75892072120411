<template>
  <div v-if="job">
    <h1>{{ job.title }}</h1>
    <p>{{ job.time }} on {{ job.date }} @ {{ job.location }}</p>
    <p>{{ job.description }}</p>
  </div>
</template>

<script>
export default {
  props: ['id'],
  created() {
    this.$store.dispatch('fetchJob', this.id)
    .catch(error => {
      this.$router.push({
        name: 'ErrorDisplay',
        params: { error: error }
      })
    })
  },
  computed: {
    job() {
      return this.$store.state.job
    }
  }
}
</script>

<style>

</style>