<template>
  <h1>Machines Job Status</h1>
  <div class="machines">
    <MachineRow v-for="machine in machines" :key="machine.id" :machine="machine" />
  </div>
</template>

<script>
// @ is an alias to /src
import MachineRow from "@/components/MachineRow.vue";
import MachineService from '@/services/MachineService.js'

export default {
  name: "MachineQueue",
  components: {
    MachineRow,
  },
  data() {
    return {
      machines: [
        {
          id: 5928101,
          category: "animal welfare",
          title: "CNC Cutting Machine",
          description: "Find your new feline friend at this event.",
          location: "Meow Town",
          date: "January 28, 2024",
          time: "12:00",
          petsAllowed: true,
          organizer: "Kat Laydee",
          jobs: [
            {
              id: 5928101,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: true
            },
            {
              id: 5928102,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
            {
              id: 5928103,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
            {
              id: 5928104,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
            {
              id: 5928105,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
          ],
        },
        {
          id: 4582797,
          category: "food",
          title: "Lamination Machine",
          description: "Join us as we tend to the community edible plants.",
          location: "Flora City",
          date: "March 14, 2024",
          time: "10:00",
          petsAllowed: true,
          organizer: "Fern Pollin",
          jobs: [
            {
              id: 5928101,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: true
            },
            {
              id: 5928102,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
            {
              id: 5928105,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
          ],
        },
        {
          id: 8419988,
          category: "sustainability",
          title: "Printing Machine",
          description: "Help pick up trash along the shore.",
          location: "Playa Del Carmen",
          date: "July 22, 2024",
          time: "11:00",
          petsAllowed: false,
          organizer: "Carey Wales",
          jobs: [
            {
              id: 5928101,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: true
            },
            {
              id: 5928102,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
            {
              id: 5928103,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
            {
              id: 5928104,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
            {
              id: 5928105,
              name: "Camfil Job 1589",
              description: 'Adam',
              timeRemaining: '4',
              processesRemaining: '5',
              processing: false
            },
          ],
        },
      ],
    };
  },
  created() {
    MachineService.getMachines()
      .then(response => {
        console.log('machines:', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
};
</script>

<style scoped>
.machines {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>