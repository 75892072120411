import axios from 'axios'

const apiClient = axios.create({
    baseURL: 'http://ppc.winsights.in/',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    getMachines() {
        return apiClient.get('/machines')
    },
    getMachine(id) {
        return apiClient.get('/machines'+ id)
    },
    postMachine(event) {
        return apiClient.post('/machines', event)
    }
}