<template>
  <router-link class="job-link" :to="{ name: 'JobDetails', params: {id: job.id } }">
    <div class="job-card">
      <h2>{{ job.name }} for {{ job.customer}}</h2>
      <h3>From : {{ job.owner}}</h3>
      <span> {{ job.daysRemaining }} days remaining for delivery</span>
      <h4>Status: {{ job.status }}</h4>
      <h5>{{ job.processesRemaining }} processes remaining</h5>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "JobCard",
  props: {
    job: Object,
  },
};
</script>

<style scoped>
.job-card {
  padding: 20px;
  width: 80vw;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 18px;
}
.job-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.job-link {
  color: #2c3e50;
  text-decoration: none;
}
</style>
